@font-face {
  font-family: 'Avander';
  src: local('Avander'), url("./assets/Avander.ttf") format('truetype');
}

.font-avander {
  font-family: 'Avander';
}

.btn-gradient {
  background: linear-gradient(90.36deg, #4AAD57 0.31%, #D4EC60 99.71%);
}

.hero-bg {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.29)), url("./assets/gravesgif.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


.video video {
  object-fit: fill;
}

.bg-roadmap.tombstone {
  background: url("./assets/roadmap-02.svg") no-repeat center center/contain;
}

@media screen and (min-width: 1024px) {
  .section-inset {
    box-shadow: -1px 30px 23px 2px rgba(0, 0, 0, 0.46) inset;
  }

  .bg-roadmap {
    background: url("./assets/teamBg.svg") no-repeat center center/contain;
  }

  .bg-roadmap-lg {
    background: url("./assets/roadmap-bg-big.svg") no-repeat center center/contain;
  }

}